import React from 'react';
import veri from "./MapIt-Check.png";
import "./Complete.css";

const Complete = () => {
  return(
    <div className="completeDiv">
      <img className="checkImg" src={veri}></img>
      <strong className="msg">Email verified</strong>
    </div>
  );
}

export default Complete;