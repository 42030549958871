import React from "react";
import "./App.css";
import AccessCode from "./Components/AccessCode/AccessCode";
import Navigation from './Components/Navigation/Navigation';
import UserMsg from "./Components/UserMsg/UserMsg";
import {TailSpin} from 'react-loader-spinner';
import UserInput from "./Components/UserInput/UserInput";
import SubmitButton from "./Components/SubmitButton/SubmitButton";
import Complete from "./Components/Complete/Complete";

import {isMobile} from 'react-device-detect';

export default class App extends React.Component {
  state = {
    codeURL: "",
    accessCode: '',
    email: '',
    app: 'val',
    clicked: false,
  }

  charNotAllowList = ["'",";",':'];

  prod_host = "https://infinite-mountain-18925.herokuapp.com";
  dev_host = "http://127.0.0.1:3001";

  componentDidMount() {
    const urlSplit = window.location.href.split('/');
    const emailId = urlSplit[urlSplit.length - 1];
    
    fetch(`${this.prod_host}/genEmailAuthCode`, {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        emailId: emailId
      })
    })
    .then(response => response.json())
    .then(data => {
      if(data.dataURL && data.email) {
        this.setState({email: data.email})
        this.setState({codeURL: data.dataURL})
      }
    })
    .catch(console.log)
  }


  checkNotAllowList = (input) => {
    for (let char of this.charNotAllowList) {
        if (input.includes(char)) {
            return false;
        }
    }
    return true;
  }

  valCodeInput = (accessCode) => {
    if (this.checkNotAllowList(accessCode) && accessCode.length === 6) {
      return true;
    } else {
      return false;
    }
  }

  onSubmit = () => {
    this.setState({clicked: !this.state.clicked});
    if (this.valCodeInput(this.state.accessCode)) {
      fetch(`${this.prod_host}/valAccessCode`, {
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            email: this.state.email,
            code: this.state.accessCode
        })
      })
      .then(response => response.json())
      .then(msg => {
        if(msg === this.state.accessCode) {
          this.setState({app: "complete"});
        } else {
          console.log(msg);
          // let user know auth failed - todo
        }
      })
      .catch(error => {
          console.log(`Debug Log: Excepton occurred in method emailValdation or it's callback: ${error}.`);
          // notify user - todo
      });
    } else {
      // notify user to try again something is wrong - todo
    }
  }

  onCodeChange = (event) => {
    this.setState({accessCode: event.target.value});
  }

  render() {
    return (
      <div className="container">
        <div className={isMobile ? "mInner":"inner"}>
        { this.state.app === "val"
        ?
        <div className="content">
          <Navigation />
          <UserMsg />
          { this.state.codeURL !== "" 
          ? 
          <AccessCode imgSrc={this.state.codeURL} />
          :
          <div className="loading">
            <TailSpin
                height="4vh"
                width="4vh"
                color='#FCC81A'
                ariaLabel='loading'
            />
          </div>
          }
          <UserInput labelValue={("Verification Code")} onInputChange={this.onCodeChange}/>
          <SubmitButton onSubmit={this.onSubmit} clicked={this.state.clicked}/>
        </div>
        :
        <Complete />
        }
        </div>
      </div>
    );
  }
}