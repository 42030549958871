import React from 'react';
import "./AccessCode.css";
import {isMobile} from 'react-device-detect';

const AccessCode = ({imgSrc}) => {
  return(
    <div className="accessCodeDiv">
        <img className={isMobile ? "mAccessCodeImg":"accessCodeImg"} src={imgSrc} alt="Access Code"></img>
    </div>
  );
}

export default AccessCode;

