import React from 'react';
import "./UserMsg.css";

const UserMsg = () => {
  return(
    <div className="userMsg">
        <strong>Enter the code below to finish your registration</strong>
    </div>
  );
}

export default UserMsg;

