import React, {useState} from 'react';
import "./SubmitButton.css"
import {isMobile} from 'react-device-detect';

const SubmitButton = ({onSubmit, clicked}) => {

  return(
    <div className="submitButtonDiv">
        <button
        onClick={onSubmit}
        className={isMobile&&!clicked ? "mSubmitButton" : (isMobile&&clicked ? "mSubmitButtonClicked" : (clicked ? "submitButtonClicked":"submitButton"))}>
        Submit
        </button>
    </div>
  );
}

export default SubmitButton;

