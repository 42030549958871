import React, {useState} from 'react';
import "./UserInput.css";
import {isMobile} from 'react-device-detect';



const UserInput = ({onInputChange, labelValue}) => {
  return(
    <div className="userInput">
    <label type="text" className="userInputLabel">{labelValue}</label>
        <input
        className={isMobile ? "mInput":"input"}
        type="text"
        name="user input"
        id="user_input"
        onChange={onInputChange}
        />
    </div>
  );
}

export default UserInput;