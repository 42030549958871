import React from 'react';
import Logo from "./mapit_50%.png";
import "./Navigation.css";

const Navigation = () => {
  return(
    <div className="navBar">
      <img className="logo" src={Logo}></img>
    </div>
  );
}

export default Navigation;